#sidebarButton {
  width: 40px;
  height: 40px;
  margin-right: 5%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
}
.line {
  width: 75%;
  height: 2px;
  background-color: white;
  margin: 0;
}
.sidebar {
  display: none;
  margin: 0;
  padding: 0;
}
.sidebar.open {
  display: block;
}
#sidebarContent {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
}
#sidebarTop {
  background-color: white;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#sidebarLogo {
  color: black;
  font-size: x-large;
}
#sidebarTopBorder {
  height: 2px;
  background-color: grey;
  width: 75%;
  margin: auto;
}
#sidebarMenuItems {
  height: 55%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-bottom: 20%;
  padding-top: 5%;
  padding-left: 10%;
}
#sidebarBottom {
  display: flex;
  height: 20%;
  width: 100%;
  padding-bottom: 5%;
  padding-top: 0;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: fixed;
  bottom: 0;
}
#sidebarLogoImage {
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  border-radius: 7px;
}
#sidebarLogo {
  display: flex;
  align-items: center;
  margin: 5%;
  font-size: x-large;
}
#exitButton {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid black;
  background-color: white;
}
#exitButtonText {
  font-size: xx-large;
}
.sidebarMenuItem {
  color: black;
  font-size: x-large;
}
@media (min-width: 1025px) {
  #sidebarButton {
    display: none;
  }
}
