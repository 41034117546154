body {
  width: 100%;
}
.navbarText {
  white-space: nowrap;
}
#menuButton {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5%;
}
#menuItems {
  display: flex;
  flex-wrap: nowrap;
  font-size: medium;
  margin-right: 5%;
}
.menuItem {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 30px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
#download {
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 7%;
}
#downloadAnchor {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  border-radius: 50px;
}
#downloadText {
  font-size: medium;
  color: #292c2e;
}
#menuButtonAnchor {
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  text-decoration: none;
  color: white;
  white-space: nowrap;
}
/*div {
  width: 100%;
  text-align: center;
}*/
span {
  display: inline-block;
  padding: 0;
  margin: 0;
}
#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #582638;
  opacity: 1;
  padding-top: 0.7%;
  padding-bottom: 0.7%;
  padding-left: 2%;
  font-family: sans-serif;
  font-size: large;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
#logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 7px;
}
/* #root {
  position: sticky;
  top: 0;
  margin: -20px;
} */
@media (max-width: 1024px) {
  #navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
  }
  #menuItems {
    display: none;
  }
  #download {
    display: none;
  }
  #menuButton {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 3%;
    font-size: large;
    font-weight: normal;
  }
  #logo {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 7px;
  }
}
