@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'KG_Blank_Space_Solid';
    src: url('../public/fonts/KGBlankSpaceSolid.ttf');
}

@font-face {
    font-family: 'SF_Compact';
    src: url('../public/fonts/SF-Compact.ttf');
}

html { scroll-behavior: smooth !important; }